import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _60dd2d64 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _4e24e34f = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _2c627798 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _5669871f = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _bd6919e4 = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _4d5f875e = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _77cd43fd = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _7a4ce17d = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _570a20f6 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _83141b56 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _4a91e852 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _59d6b034 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _d9a81406 = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _5a926b2b = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _2814b4a2 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _65677d64 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _23f7f8be = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _c93c3e56 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _a08b53ce = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _43b4f96e = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _cf49bf48 = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _0be94833 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _48b1c412 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _f8111624 = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _40121cb1 = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _a5039b68 = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _31ea0bc7 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _5b3baef7 = () => interopDefault(import('../pages/onboarding/my-tax-liability.vue' /* webpackChunkName: "pages/onboarding/my-tax-liability" */))
const _1efa9b84 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _d2bc7234 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _30e3a8f1 = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _7cafe6fe = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _941779ac = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _b93a61f4 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _2890c1b4 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _5d0edbf2 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _31cbd86c = () => interopDefault(import('../pages/onboarding/tax-payment-methods.vue' /* webpackChunkName: "pages/onboarding/tax-payment-methods" */))
const _2370ce96 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _40970f1c = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _25321da1 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _70a365f4 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _35d327ea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _60dd2d64,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _4e24e34f,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _2c627798,
    name: "help"
  }, {
    path: "/verify-email",
    component: _5669871f,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _bd6919e4,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _4d5f875e,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _77cd43fd,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _7a4ce17d,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _570a20f6,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _83141b56,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _4a91e852,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _59d6b034,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _d9a81406,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _5a926b2b,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _2814b4a2,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _65677d64,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _23f7f8be,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _c93c3e56,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _a08b53ce,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _43b4f96e,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _cf49bf48,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _0be94833,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _48b1c412,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _f8111624,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _40121cb1,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _a5039b68,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _31ea0bc7,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/my-tax-liability",
    component: _5b3baef7,
    name: "onboarding-my-tax-liability"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _1efa9b84,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _d2bc7234,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _30e3a8f1,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _7cafe6fe,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _941779ac,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _b93a61f4,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _2890c1b4,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _5d0edbf2,
    name: "onboarding-suez-info"
  }, {
    path: "/onboarding/tax-payment-methods",
    component: _31cbd86c,
    name: "onboarding-tax-payment-methods"
  }, {
    path: "/personal/account",
    component: _2370ce96,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _40970f1c,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _25321da1,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _70a365f4,
    name: "blog-slug"
  }, {
    path: "/",
    component: _35d327ea,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
